<template>
    <h1>{{labels.pageHeading}}</h1>
  <h6>{{labels.pageSubheading}}</h6>
  <form class="login-form">
    <div class="buttons-container">
      <mcw-button
        @click.prevent="showDialog = !showDialog"
        raised>
        {{labels.notifyUnauthorized}}
      </mcw-button>
    </div>
    <h6>{{labels.pageSubheading_2}}</h6>
    <router-link
      to='/reset-password'>
      {{labels.resetPasswordLink}}
    </router-link>
  </form>
  <mcw-dialog
    v-model="showDialog"
    escape-key-action="close"
    scrim-click-action="close"
    aria-labelledby="my-basic-title"
    aria-describedby="my-basic-content"
    :auto-stack-buttons="true"
  >
  <mcw-dialog-title>{{labels.dialogHeading}}</mcw-dialog-title>
  <mcw-dialog-content>
    <mcw-textfield
        v-model.trim="name"
        :label="labels.name"
        required
        outline
    ></mcw-textfield>
    <mcw-textfield
        v-model.trim="email"
        :label="labels.email"
        required
        outline
    ></mcw-textfield>
    <mcw-textfield
        v-model.trim="phone"
        :label="labels.phone"
        required
        outline
    ></mcw-textfield>
  </mcw-dialog-content>

  <mcw-dialog-footer>
    <mcw-dialog-button @click.prevent="sendSignal">
      {{labels.send}}
    </mcw-dialog-button>
    <mcw-dialog-button @click.prevent="showDialog = !showDialog">
      {{labels.cancel}}
    </mcw-dialog-button>
  </mcw-dialog-footer>
</mcw-dialog>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      labels: {
        pageHeading: 'Персональная ссылка на первый вход в систему уже была использована',
        pageSubheading: 'Если ее использовали не вы, нажмите:',
        pageSubheading_2: 'Если вы хотите сбросить пароль и получить новую ссылку на электронную почту, нажмите:',
        notifyUnauthorized: 'сообщить о несанкционированном использовании',
        resetPasswordLink: 'Сбросить пароль',
        dialogHeading: 'Укажите, с кем и как связаться технической поддержке MedSafe по вопросу несанкционированного использования ссылки',
        send: 'отправить',
        cancel: 'отменить',
        name: 'Ваше имя',
        email: 'Ваш E-mail',
        phone: 'Ваш номер телефона',
      },
      showDialog: false,
      name: '',
      email: '',
      phone: '',
    };
  },
  methods: {
    async sendSignal() {
      const result = await this.$store.dispatch('auth/unAuthorized', {
        name: this.name,
        email: this.email,
        phone: this.phone,
      });
      if (result) {
        this.$router.push('/login');
      }
    },
  },
};
</script>

<style lang="scss">
  @use '@material/dialog/mdc-dialog';
  .textfield-container{
    margin: 20px auto;
  }
</style>
